/**
 * @return {string}
 */
import React from "react";
import HiloReactComponent from "../layout/HiloReactComponent";

export default class NotFound extends HiloReactComponent<{}, {}> {
    render() {
        return <>
            Requested URL is not available.
        </>;
    }
}


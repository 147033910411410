import HiloReactComponent from "../view/layout/HiloReactComponent";
import React from "react";
import Error from "../view/element/Error";

interface Props {
    statusCode?: number
}

export default class HttpError extends HiloReactComponent<Props, {}> {
    public render(): React.ReactNode {
        const {statusCode} = this.props;

        if (!statusCode || (statusCode && statusCode === 201) || (statusCode && statusCode === 200)) {
            return <></>
        }

        let content: string;

        switch (statusCode) {
            case 401:
                content = this.t["401"]
                break;
            case 404:
                content = this.t["404"]
                break;
            case 409:
                content = this.t["409"]
                break;
            case 422:
                content = this.t["422"]
                break;
            default:
                content = this.t["500"]
                break;
        }

        return <Error content={t => content}/>
    }
}
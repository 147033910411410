import {APIPagination} from "../tools/pagination/APIPagination";
import {Role} from "../tools/model/enumerations/Role";
import {Criterion} from "../tools/model/pagination/Criterion";

export class UserAPIPagination extends APIPagination {
    role?: string

    /**
     * setCriterion
     * @param criterion
     */
    setCriterion(criterion: Criterion<any>): void {
        switch (criterion.relatedFilterKey) {
            case "role":
                if (criterion.value === "all") this.role = undefined;
                else this.role = criterion.value as Role;

                break;
        }

        super.setCriterion(criterion);
    }
}

import { FormException } from "./FormException";
import { Field } from "./Field";
import { AbstractDTO } from "../dto/AbstractDTO";

export interface FormListener<Form extends AbstractForm<DTO>, DTO extends AbstractDTO> {
    onFormUpdate: (form: AbstractForm<DTO>) => void;
}

export abstract class AbstractForm<DTO extends AbstractDTO> {
    public fields: Map<string, Field<any>> = new Map<string, Field<any>>();
    private _statusCode?: number;
    private _listener?: FormListener<AbstractForm<DTO>, DTO>;

    public addListener = (listener: FormListener<AbstractForm<DTO>, DTO>) => {
        this._listener = listener;
    }

    public removeListener = (listener: FormListener<AbstractForm<DTO>, DTO>) => {
        this._listener = undefined;
    }

    private _notifyListener = () => {
        this._listener?.onFormUpdate(this)
    }

    /**
     * compile
     */
    public compile = (): FormData => {
        let formData = new FormData();

        for (let field of this.fields.values()) {
            if (typeof field.value !== "boolean" && !(field.value) && field.required) {
                throw new FormException(field);
            }

            if (!field.isValid(field.value)) {
                throw new FormException(field);
            }

            const stringValue = field.stringValue();
            formData.append(field.name, stringValue ?? "");
        }
        return formData;
    }

    /**
     * load
     */
    public load(dto: DTO) {
        const properties = Object.getOwnPropertyNames(dto);

        for (let field of this.fields.values()) {

            for (let property of properties) {
                if (property === field.name) {
                    // @ts-ignore
                    field.value = dto[property];
                }
            }
        }

        this._notifyListener();
    }

    /**
     * _clearForm
     * @private
     */
    public _clearForm() {
        for (let field of this.fields.values()) field.value = field.defaultValue;
    }

    /**
     * applyValue
     * @param fieldName
     * @param fieldValue
     */
    public applyValue = (fieldName: string, fieldValue: any): Map<string, Field<any>> => {
        const field = this.fields.get(fieldName)
        if (field) field.value = fieldValue;

        this._notifyListener();
        return this.fields
    };

    public get statusCode(): number | undefined {
        return this._statusCode;
    }

    public set statusCode(code: number | undefined) {
        const failCodes = [401, 404, 409, 422, 500];
        const successCodes = [200, 201];

        const isAFailCode = code && (failCodes.find(failCode => failCode === code))
        const isASuccessCode = code && (successCodes.find(successCode => successCode === code))

        if (!isAFailCode && !isASuccessCode) code = 500;

        this._statusCode = code;
        this._notifyListener()
    }
}
import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";

export class UserAddDTO extends AbstractDTO {
    static LASTNAME = "lastname"
    static FIRSTNAME = "firstname"
    static EMAIL = "email"
    static PASSWORD = "password"
    static PHONE = "phone"
    static CLIENT_IDS = "client_ids"

    constructor(lastname: string, firstname: string, email: string, password: string, phone: string, client_ids: string) {
        super();
        this.lastname = lastname;
        this.firstname = firstname;
        this.email = email;
        this.password = password;
        this.phone = phone;
        this.client_ids = client_ids;
    }

    lastname: string
    firstname: string
    email: string
    phone: string
    password: string
    client_ids: string
}
import {LabelAccessor} from "../../view/LabelAccessor";
import {Type} from "../../view/form/FormInput";
import {Field} from "./Field";
import {Selectable} from "./Selectable";

export class SelectField<T> extends Field<T> {
    options: Array<Selectable<T>>;
    multiple: boolean;

    constructor(options: Array<Selectable<T>>,
                name: string,
                title: LabelAccessor,
                isValid: () => boolean,
                type: Type,
                multiple: boolean,
                required: boolean,
                value: T | Array<T> | undefined,
                defaultValue: T | Array<T> | undefined,
                disabled: boolean = false,
                errorLabel?: LabelAccessor) {
        super(name, title, isValid, type, required, value, defaultValue, disabled, errorLabel);
        this.options = options;
        this.multiple = multiple;
    }

    stringValue = (): string | undefined => {
        if (this.value instanceof Array) return this.value.map(item => {
            let index = -1;
            let id: string | undefined = undefined;

            if (typeof item === "string"){
                return item;
            }

            Object.keys(item).forEach((value, index1) => index = (value === "id") ? index1 : index)
            Object.values(item).forEach(((value, index1) => id = (index1 === index) ? value : id))

            return id;
        }).join(",")

        return undefined
    };
}
import HiloReactComponent from "../../../tools/view/layout/HiloReactComponent";
import User from "../../../models/User";
import {FormListener} from "../../../tools/model/form/AbstractForm";
import FormSection from "../../../tools/view/form/FormSection";
import React from "react";
import UIToast from "../../../tools/view/toast/UIToast";
import UserService from "../../../services/UserService";
import UserClientForm from "../../../models/form/user/UserClientForm";
import {UserClientDTO} from "../../../models/dto/user/UserClientDTO";
import ClientService from "../../../services/ClientService";
import Client from "../../../models/Client";
import Spinner from "../../../tools/view/element/Spinner";
import UIError from "../../../tools/view/toast/UIError";

interface State {
    clientForm?: UserClientForm;
    clients?: Array<Client>;
    loadingClients: boolean;
}

interface Props {
    user: User;
    userUpdated: () => void;
}

export default class AdminUserClientEditor
    extends HiloReactComponent<Props, State>
    implements FormListener<UserClientForm, UserClientDTO> {

    private readonly _userService: UserService;
    private readonly _clientService: ClientService;

    constructor(props: Props, state: State) {
        super(props, state);

        this._userService = this._serviceRepository.resolve(UserService.id) as UserService;
        this._clientService = this._serviceRepository.resolve(ClientService.id) as ClientService;

        this.state = {loadingClients: false};
    }

    /**
     * componentDidMount
     */
    public componentDidMount(): void {
        super.componentDidMount();

        this._refreshForms();
    }

    /**
     * componentWillUnmount
     */
    public componentWillUnmount(): void {
        this.state.clientForm?.removeListener(this);

        super.componentWillUnmount();
    }

    /**
     * componentDidUpdate
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        super.componentDidUpdate?.(prevProps, prevState, snapshot);

        if (prevProps.user.id !== this.props.user.id) {
            this._refreshForms();
        }
    }

    /**
     * _initClientForm
     * @param user
     * @param clients
     * @private
     */
    private _initClientForm(user: User, clients: Array<Client>): UserClientForm {
        const clientForm = new UserClientForm(user, clients);

        clientForm.addListener(this);

        return clientForm;
    }

    /**
     * _refreshForms
     * @private
     */
    private _refreshForms = async () => {
        const {user} = this.props;
        const {loadingClients, clients, clientForm} = this.state;

        if (loadingClients) return;
        if (!clients) return await this._loadClients();

        if (!clientForm) await this.setState({clientForm: this._initClientForm(user, clients)});
    };

    private _loadClients = async () => {
        await this.setState({loadingClients: true});

        try {
            await this.setState({clients: await this._clientService.findAll(), loadingClients: false});
            await this._refreshForms();
        } catch (e) {
            UIError.default.show(e.statusCode)
            await this.setState({loadingClients: false});
        }
    };


    /**
     * onFormUpdate
     * @param form
     */
    public onFormUpdate = (form: UserClientForm) => {
        this.setState({clientForm: form})
    };

    /**
     * _submitForm
     * @param action
     * @param form
     * @private
     */
    private _submitForm = async (action: () => void, form: UserClientForm) => {
        try {
            await action()
            form.statusCode = 200;
            UIToast.default.show(this.t.userEdited);
            this.props.userUpdated();
        } catch (e) {
            form.statusCode = e.status
            console.error(e);
        }
    }

    /**
     * _saveUser
     * @param dto
     * @private
     */
    private _saveUser = async (dto: FormData) => {
        const {clientForm} = this.state;

        if (!clientForm) return console.error("ClientForm undefined");

        await this._submitForm(() => this._userService.updateClients(this.props.user, dto), clientForm)
    };


    render(): React.ReactNode {
        const {loadingClients, clientForm} = this.state;

        if (loadingClients || !clientForm) {
            return <Spinner large={true}/>;
        }

        return <>
            <FormSection label={this.t.clients} form={clientForm} submit={this._saveUser}/>
        </>
    }
}
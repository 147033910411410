import React from "react";

export interface IonIconProps {
    name: string,
    color?: string
}

export class IonIcon extends React.Component<IonIconProps, {}> {
    render() {
// @ts-ignore
        return <ion-icon class={"ion-icon"} style={{color: this.props.color}} name={this.props.name}/>;
    }
}

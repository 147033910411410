import QueryFilter from "../tools/model/pagination/Filters";
import {PaginationFilters} from "../services/ClientService";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import AccessTokenService, {AccessTokenWrapper, TokenSubjectType} from "../services/AccessTokenService";
import HTTPService from "../tools/service/HTTPService";
import {AccessTokenAPIPagination} from "../pagination/AccessTokenAPIPagination";

export interface AccessTokenFilters extends PaginationFilters {
    revoked?: boolean
}

export default class AccessTokenDataProvider extends DataProvider<AccessTokenWrapper, AccessTokenAPIPagination> {
    private readonly _accessTokenService: AccessTokenService;
    private readonly _httpService: HTTPService;
    private readonly _tokenType: TokenSubjectType;
    private _tokenSubjectId?: string;

    /**
     * constructor
     * @param accessTokenService
     * @param httpService
     * @param tokenType
     * @param tokenSubjectId
     */
    constructor(accessTokenService: AccessTokenService, httpService: HTTPService, tokenType: TokenSubjectType, tokenSubjectId?: string) {
        super(new PaginationController<AccessTokenWrapper, AccessTokenAPIPagination>(new AccessTokenAPIPagination()));

        this._httpService = httpService;
        this._accessTokenService = accessTokenService;
        this._tokenType = tokenType;
        this._tokenSubjectId = tokenSubjectId;
    }

    /**
     * _getRoute
     * @returns {string}
     * @private
     */
    private _getRoute(): string {
        const route = this._tokenType === TokenSubjectType.USER ? AccessTokenService.USER_TOKEN_LIST : AccessTokenService.CLIENT_TOKEN_LIST;
        return this._httpService.buildRoute(route, {id: this._tokenSubjectId});
    }

    /**
     * load
     * @returns {Promise<AccessTokenWrapper[]>}
     */
    async load(): Promise<AccessTokenWrapper[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters = new QueryFilter<AccessTokenFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10,
        });

        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`
        if (pagination.searchQuery) paginationFilters.fields.searchQuery = pagination.searchQuery;
        if (pagination.revoked !== undefined) paginationFilters.fields.revoked = pagination.revoked

        const pageResponse = await this._accessTokenService.getPage<AccessTokenWrapper>(this._getRoute(), paginationFilters);
        this.paginationController.pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }

    /**
     * tokenSubjectId
     * @param sId
     */
    public set tokenSubjectId(sId: string) {
        this._tokenSubjectId = sId;
        this.load();
    }
}
import Translation from "./Translation";

export default class TranslationEN extends Translation {
    public readonly languageKey: string = "en";
    public readonly languageFlag: string = "🇺🇸";
    public readonly 401: string = "Invalid form: unauthorized. Please reload current page.";
    public readonly 404: string = "Invalid form: resource not found";
    public readonly 409: string = "Invalid form: duplicate";
    public readonly 422: string = "Invalid form: check inputs";
    public readonly 500: string = "Invalid form: internal server error";
    public readonly all: string = "All";
    public readonly admin: string = "Admin";
    public readonly loginFormMailPh: string = "Mail";
    public readonly loginFormPwdPh: string = "Password";
    public readonly loginFormError: string = "Login error, please verify your credentials";
    public readonly vegetableTypeSelect: string = "Vegetable type";
    public readonly nut: string = "Nut";
    public readonly flower: string = "Flower";
    public readonly cereal: string = "Cereal";
    public readonly fruit: string = "Fruit";
    public readonly navbarBtnEdit: string = "Edit";
    public readonly addBillModalBillNb: string = "Bill number";
    public readonly addBillModalHelp: string = "You will be able to attach models to this bill later";
    public readonly selected: string = "Selected";
    public readonly see: string = "See";
    public readonly edit: string = "Edit";
    public readonly save: string = "Save";
    public readonly cancel: string = "Cancel";
    public readonly android: string = "Android";
    public readonly iOS: string = "iOS";
    public readonly web: string = "Web";
    public readonly information: string = "Information";
    public readonly type: string = "Type";
    public readonly bill: string = "Bill";
    public readonly bills: string = "Bills";
    public readonly manager: string = "Manager";
    public readonly clients: string = "Clients";
    public readonly usersAndAccess: string = "Users and access";
    public readonly settings: string = "Settings";
    public readonly supplier: string = "Supplier";
    public readonly suppliers: string = "Suppliers";
    public readonly group: string = "Group";
    public readonly member: string = "Members";
    public readonly number: string = "Number";
    public readonly status: string = "Status";
    public readonly delete: string = "Delete";
    public readonly action: string = "Action";
    public readonly modelName: string = "Model name";
    public readonly fileName: string = "Filename";
    public readonly weight: string = "Size";
    public readonly download: string = "Download";
    public readonly creationDate: string = "Created at";
    public readonly headerLogout: string = "Log out";
    public readonly headerLogoutConfirm: string = " Log out ?";
    public readonly headerTitle: string = "Administration panel";
    public readonly headerNoData: string = "No data for this user.";
    public readonly modalPositiveBtn: string = "Save";
    public readonly modalNegativeBtn: string = "Close";
    public readonly addUserModalDuplicate: string = "This member is already in group";
    public readonly addUserModalMail: string = "Mail address (Hilo ID)";
    public readonly addUserModalFirstName: string = "First name";
    public readonly addUserModalLastName: string = "Last name";
    public readonly UserComponentResetPassword: string = "You will reset this user's password. User will receive a mail containing a reset link.";
    public readonly UserComponentResetPasswordConfirm: string = "Successful reset password";
    public readonly UserComponentRemoveUser: string = "You will remove this user from this group. He will no longer be able to access group's models.";
    public readonly UserComponentRemoveUserConfirm: string = "Removed member";
    public readonly UserComponentTitle: string = "Group's settings";
    public readonly UserComponentAddUserBtn: string = "Add member";
    public readonly groupComponentMemberListTab: string = "Members";
    public readonly loading: string = "Loading";
    public readonly identifier: string = "ID";
    public readonly clientListColID: string = "ID";
    public readonly clientListColName: string = "Name";
    public readonly clientListColEnabled: string = "Active";
    public readonly clientListColScope: string = "Scope";
    public readonly clientListEmpty: string = "No clients";
    public readonly userListColID: string = "Adresse mail";
    public readonly userListColRole: string = "Role";
    public readonly userListColMultipleConnection: string = "Multiple connections";
    public readonly userListColFirstName: string = "First name";
    public readonly userListColLastName: string = "Last name";
    public readonly userListColLastPwd: string = "Password";
    public readonly userListColPhone: string = "Phone number";
    public readonly userListColAction: string = "Action";
    public readonly userListEmpty: string = "No members";
    public readonly userListResetPwdBtn: string = "Reset password";
    public readonly userListRemoveUserFromGrpBtn: string = "Remove from group";
    public readonly userListClientsIds: string = "Clients";
    public readonly searchTitle: string = "Search";
    public readonly searchByNamePH: string = "Search by name";
    public readonly searchBtn: string = "Search";
    public readonly searchFilterTitle: string = "Filter";
    public readonly searchClearBtn: string = "Clear";
    public readonly modelDescriptionCancelConfirm: string = "You will lose your modifications. Are you sure ?";
    public readonly settingsPwdReset: string = "Password reset";
    public readonly settingsResetPasswordConfirm: string = "You will reset this password";
    public readonly alertOk: string = "OK";
    public readonly alertCancel: string = "Cancel";
    public readonly switchLanguage: string = "Français/English";
    public readonly formInvalidField: string = "Invalid input";
    public readonly formPasswordInvalid: string = "Invalid password. Minimal length : 6 characters";
    public readonly userAdded: string = "User added";
    public readonly userEdited: string = "User edited";
    public readonly clientAdded: string = "Client added";
    public readonly clientEdited: string = "Client edited";
    public readonly password: string = "Password";
    public readonly client_credentials: string = "Client credentials";
    public readonly refresh_token: string = "Refresh token";
    public readonly grantTypes: string = "Grant types";
    public readonly openRegistration: string = "Registration opened";
    public readonly openRegistrationShort: string = "Opened";
    public readonly redirectUri: string = "Redirect URI";
    public readonly details: string = "Details";
    public readonly list: string = "List";
    public readonly allClients: string = "Clients list";
    public readonly allUsers: string = "Users list";
    public readonly expired: string = "Expired";
    public readonly valid: string = "Valid";
    public readonly token: string = "Token";
    public readonly tokenListEmpty: string = "No tokens";
    public readonly tokenListAccessToken: string = "Access token";
    public readonly tokenListRefreshToken: string = "Refresh token";
    public readonly tokenListCreatedAt: string = "Created at";
    public readonly tokenListExpiresIn: string = "Expires in";
    public readonly pageTotal: string = "%%% element(s) of %%%";
    public readonly identity: string = "Identity";
    public readonly oldPassword: string = "Old password";
    public readonly newPassword: string = "New password";
    public readonly saved: string = "Saved updates";
    public readonly revoke: string = "Revoke";
    public readonly revoked: string = "Revoked";
    public readonly accessTokenCopied: string = "Access token sent to clipboard";
    public readonly refreshTokenCopied: string = "Refresh token sent to clipboard";
    public readonly accountStatus: string = "Account status";
    public readonly roles: string = "Roles";
    public readonly ADMIN: string = "Admin";
    public readonly MANAGER: string = "Manager";
    public readonly STANDARD: string = "Standard";
    public readonly newUser: string = "New user";
    public readonly newClient: string = "New client";
    public readonly userMenu: string = "User menu";
    public readonly pages: string = "Pages";
    public readonly devices: string = "Devices";
    public readonly device: string = "Device";
    public readonly uuid: string = "UUID";
    public readonly clientId: string = "Client ID";
    public readonly clientSecret: string = "Client secret";
    public readonly deviceListEmpty: string = "No device associated";
    public readonly copied: string = "Copied";
    public readonly enabled: string = "Enabled";
    public readonly activation: string = "Activation";
    public readonly newDevice: string = "New device";
    public readonly deviceCreated: string = "Device created";
    public readonly deviceRemoveConfirmation: string = "This device will be removed";
    public readonly formPhoneInvalid: string = "Invalid phone";
    public readonly name: string = "Name";
    public readonly deviceName: string = "Device name";
    //PH
}
import {APIPagination} from "../tools/pagination/APIPagination";
import {Criterion} from "../tools/model/pagination/Criterion";

export class AccessTokenAPIPagination extends APIPagination {
    revoked?: boolean

    /**
     * setCriterion
     * @param criterion
     * @returns {undefined}
     */
    public setCriterion(criterion: Criterion<boolean>) {
        switch (criterion.relatedFilterKey) {
            case "revoked":
                if (criterion.translationKey === "all") this.revoked = undefined;
                else this.revoked = criterion.value;

                break;
        }

        super.setCriterion(criterion);
    }
}
import Translation from "./Translation";

export default class TranslationFR extends Translation {
    public readonly languageKey: string = "fr";
    public readonly languageFlag: string = "🇫🇷";
    public readonly 401: string = "Non autorisé. Essayez de rafraîchir la page";
    public readonly 404: string = "Ressource introuvable";
    public readonly 409: string = "Doublon";
    public readonly 422: string = "Vérifiez votre saisie";
    public readonly 500: string = "Erreur serveur";
    public readonly all: string = "Tous";
    public readonly admin: string = "Administrateur";
    public readonly loginFormMailPh: string = "Email";
    public readonly loginFormPwdPh: string = "Mot de passe";
    public readonly loginFormError: string = "Erreur de connexion, vérifiez votre saisie";
    public readonly vegetableTypeSelect: string = "Type de légume";
    public readonly nut: string = "Noix";
    public readonly flower: string = "Fleur";
    public readonly cereal: string = "Céréale";
    public readonly fruit: string = "Fruit";
    public readonly navbarBtnEdit: string = "Éditer";
    public readonly addBillModalBillNb: string = "Numéro de facture";
    public readonly addBillModalHelp: string = "Vous pourrez attacher des modélisations à cette facture dans un second temps.";
    public readonly selected: string = "Sélectionné";
    public readonly see: string = "Voir";
    public readonly edit: string = "Éditer";
    public readonly save: string = "Sauvegarder";
    public readonly cancel: string = "Annuler";
    public readonly android: string = "Android";
    public readonly iOS: string = "iOS";
    public readonly web: string = "Web";
    public readonly information: string = "Information";
    public readonly type: string = "Type";
    public readonly bill: string = "Facture";
    public readonly bills: string = "Factures";
    public readonly manager: string = "Manager";
    public readonly clients: string = "Clients";
    public readonly usersAndAccess: string = "Utilisateurs et accès";
    public readonly settings: string = "Paramètres";
    public readonly supplier: string = "Sous-traitant";
    public readonly suppliers: string = "Sous-traitants";
    public readonly group: string = "Groupe";
    public readonly member: string = "Membres";
    public readonly number: string = "Numéro";
    public readonly status: string = "Statut";
    public readonly delete: string = "Supprimer";
    public readonly action: string = "Action";
    public readonly modelName: string = "Nom du modèle";
    public readonly fileName: string = "Nom du fichier";
    public readonly weight: string = "Poids";
    public readonly download: string = "Télécharger";
    public readonly creationDate: string = "Date de création";
    public readonly headerLogout: string = "Déconnexion";
    public readonly headerLogoutConfirm: string = " Déconnexion ?";
    public readonly headerTitle: string = "Interface d'administration";
    public readonly headerNoData: string = "Pas de données pour cet utilisateur.";
    public readonly modalPositiveBtn: string = "Sauvegarder";
    public readonly modalNegativeBtn: string = "Fermer";
    public readonly addUserModalDuplicate: string = "Ce membre est déjà présent dans le groupe.";
    public readonly addUserModalMail: string = "Adresse mail (identifiant Hilo)";
    public readonly addUserModalFirstName: string = "Prénom";
    public readonly addUserModalLastName: string = "Nom";
    public readonly UserComponentResetPassword: string = "Vous allez réinitialiser le mot de passe de cet utilisateur. Il recevra par mail l'adresse d'une page qui lui permettra de définir un nouveau mot de passe.";
    public readonly UserComponentResetPasswordConfirm: string = "Mot de passe réinitialisé";
    public readonly UserComponentRemoveUser: string = "Vous allez retirer cet utilisateur de ce groupe. Il ne pourra plus accéder aux modèles de celui-ci.";
    public readonly UserComponentRemoveUserConfirm: string = "Membre retiré";
    public readonly UserComponentTitle: string = "Paramètres du groupe";
    public readonly UserComponentAddUserBtn: string = "Ajouter un membre";
    public readonly groupComponentMemberListTab: string = "Membres";
    public readonly loading: string = "Chargement";
    public readonly identifier: string = "Identifiant";
    public readonly clientListColID: string = "Identifiant";
    public readonly clientListColName: string = "Nom";
    public readonly clientListColEnabled: string = "Actif";
    public readonly clientListColScope: string = "Scope";
    public readonly clientListEmpty: string = "Pas de clients";
    public readonly userListColID: string = "Adresse mail";
    public readonly userListColRole: string = "Rôle";
    public readonly userListColMultipleConnection: string = "Connexion multiple";
    public readonly userListColFirstName: string = "Prénom";
    public readonly userListColLastName: string = "Nom";
    public readonly userListColLastPwd: string = "Mot de passe";
    public readonly userListColPhone: string = "N° de téléphone";
    public readonly userListColAction: string = "Action";
    public readonly userListEmpty: string = "Pas de membres";
    public readonly userListResetPwdBtn: string = "Réinitialisation du mot de passe";
    public readonly userListRemoveUserFromGrpBtn: string = "Retirer du groupe";
    public readonly userListClientsIds: string = "Clients";
    public readonly searchTitle: string = "Rechercher";
    public readonly searchByNamePH: string = "Recherche par nom";
    public readonly searchBtn: string = "Rechercher";
    public readonly searchFilterTitle: string = "Filtrer";
    public readonly searchClearBtn: string = "Effacer";
    public readonly modelDescriptionCancelConfirm: string = "Vous allez perdre vos modifications, êtes-vous sûr de vouloir stopper l'édition ?";
    public readonly settingsPwdReset: string = "Mot de passe réinitialisé";
    public readonly settingsResetPasswordConfirm: string = "Vous allez réinitialiser le mot de passe.";
    public readonly alertOk: string = "OK";
    public readonly alertCancel: string = "Annuler";
    public readonly switchLanguage: string = "Français/English";
    public readonly formInvalidField: string = "Saisie invalide";
    public readonly formPasswordInvalid: string = "Mot de passe invalide. Longueur minimale : 6 caractères.";
    public readonly userAdded: string = "Utilisateur ajouté";
    public readonly userEdited: string = "Utilisateur modifié";
    public readonly clientAdded: string = "Client ajouté";
    public readonly clientEdited: string = "Client modifié";
    public readonly password: string = "Password";
    public readonly client_credentials: string = "Client credentials";
    public readonly refresh_token: string = "Refresh token";
    public readonly grantTypes: string = "Grant types";
    public readonly openRegistration: string = "Inscriptions ouvertes au public";
    public readonly openRegistrationShort: string = "Ouvert";
    public readonly redirectUri: string = "URI de redirection";
    public readonly details: string = "Détails";
    public readonly list: string = "Liste";
    public readonly allClients: string = "Tous les clients";
    public readonly allUsers: string = "Tous les utilisateurs";
    public readonly expired: string = "Expiré";
    public readonly valid: string = "Valide";
    public readonly token: string = "Token";
    public readonly tokenListEmpty: string = "Pas de tokens";
    public readonly tokenListAccessToken: string = "Access token";
    public readonly tokenListRefreshToken: string = "Refresh token";
    public readonly tokenListCreatedAt: string = "Créé à";
    public readonly tokenListExpiresIn: string = "Expire dans";
    public readonly pageTotal: string = "%%% élément(s) sur %%%";
    public readonly identity: string = "Identité";
    public readonly oldPassword: string = "Ancien mot de passe";
    public readonly newPassword: string = "Nouveau mot de passe";
    public readonly saved: string = "Modifications sauvegardées";
    public readonly revoke: string = "Révocation";
    public readonly revoked: string = "Révoqué";
    public readonly accessTokenCopied: string = "Access token copié dans le presse-papier";
    public readonly refreshTokenCopied: string = "Refresh token copié dans le presse-papier";
    public readonly accountStatus: string = "Statut du compte";
    public readonly roles: string = "Rôles";
    public readonly ADMIN: string = "Administrateur";
    public readonly MANAGER: string = "Manager";
    public readonly STANDARD: string = "Standard";
    public readonly newUser: string = "Nouvel utilisateur";
    public readonly newClient: string = "Nouveau client";
    public readonly userMenu: string = "Menu utilisateur";
    public readonly pages: string = "Pages";
    public readonly devices: string = "Appareils";
    public readonly device: string = "Device";
    public readonly uuid: string = "UUID";
    public readonly clientId: string = "ID client";
    public readonly clientSecret: string = "Secret client";
    public readonly deviceListEmpty: string = "Pas encore d'appareils associés";
    public readonly copied: string = "Copié";
    public readonly enabled: string = "Activé";
    public readonly activation: string = "Activation";
    public readonly newDevice: string = "Nouvel appareil";
    public readonly deviceCreated: string = "Appareil créé";
    public readonly deviceRemoveConfirmation: string = "Vous allez supprimer cet appareil";
    public readonly formPhoneInvalid: string = "Téléphone invalide";
    public readonly name: string = "Nom";
    public readonly deviceName: string = "Nom de l'appareil";
    //PH
}
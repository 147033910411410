import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import User from "../../User";

export class UserActivationDTO implements AbstractDTO {
    static ENABLED = "enabled";

    constructor(enabled: boolean) {
        this.enabled = enabled;
    }

    enabled: boolean

    static fromUser = (user: User): UserActivationDTO => {
        return new UserActivationDTO(user.enabled);
    }
}
import QueryFilter from "../tools/model/pagination/Filters";
import {PaginationFilters} from "../services/ClientService";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import DeviceService from "../services/DeviceService";
import HTTPService from "../tools/service/HTTPService";
import Device from "../models/Device";
import Client from "../models/Client";
import {APIPagination, DefaultAPIPagination} from "../tools/pagination/APIPagination";

export default class DeviceDataProvider extends DataProvider<Device, APIPagination> {
    private readonly _deviceService: DeviceService;
    private readonly _httpService: HTTPService;
    private _client?: Client;

    /**
     * constructor
     * @param deviceService
     * @param httpService
     * @param client
     */
    constructor(deviceService: DeviceService, httpService: HTTPService, client?: Client) {
        super(new PaginationController<Device, APIPagination>(new DefaultAPIPagination()));

        this._httpService = httpService;
        this._deviceService = deviceService;
        this._client = client;
    }

    /**
     * load
     * @returns {Promise<Device[]>}
     */
    async load(): Promise<Device[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters = new QueryFilter<PaginationFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10,
        });

        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`

        if (this._client == null) return [];

        const url = this._httpService.buildRoute(DeviceService.LIST_ROUTE, {clientId: this._client.id})

        const pageResponse = await this._deviceService.getPage<Device>(url, paginationFilters);
        this.paginationController.pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }


    /**
     * client
     * @param client
     */
    public set client(client: Client) {
        this._client = client;
        this.load();
    }
}
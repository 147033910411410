import {GrantType} from "../../../tools/model/auth/GrantType";
import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Client from "../../Client";

export class ClientDTO extends AbstractDTO {
    static NAME = "name"
    static GRANT_TYPES = "grantTypes"
    static REDIRECT_URI = "redirectUri"
    static SCOPE = "scope"
    static OPEN_REGISTRATION = "openRegistration"
    static LOGO_URL = "logoURL"

    constructor(name: string,
                grantTypes: GrantType[],
                redirectUri: string,
                scope: string,
                openRegistration: boolean,
                logoURL: string) {
        super();
        this.name = name;
        this.grantTypes = grantTypes;
        this.redirectUri = redirectUri;
        this.scope = scope;
        this.openRegistration = openRegistration;
        this.logoURL = logoURL;

    }

    name: string
    grantTypes: GrantType[]
    redirectUri: string
    scope: string
    openRegistration: boolean
    logoURL: string

    static fromClient = (client: Client): ClientDTO => {
        return new ClientDTO(
            client.name,
            client.grant_types,
            client.redirect_uri,
            client.scope,
            client.open_registration,
            client.logo_url
        )
    };
}
import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import User from "../../User";

export class UserEditIdentityDTO implements AbstractDTO {
    static LASTNAME = "lastname";
    static FIRSTNAME = "firstname";
    static PHONE = "phone";
    static HAS_MULTIPLE_CONNECTIONS = "hasMultipleConnections";

    constructor(lastname: string, firstname: string, phone: string, hasMultipleConnections: boolean) {
        this.lastname = lastname;
        this.firstname = firstname;
        this.phone = phone;
        this.hasMultipleConnections = hasMultipleConnections;
    }

    hasMultipleConnections: boolean;
    lastname: string
    firstname: string
    phone: string

    static fromUser = (user: User): UserEditIdentityDTO => {
        return new UserEditIdentityDTO(user.lastname, user.firstname, user.phone, user.has_multiple_connections);
    }
}
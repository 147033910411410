import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Client from "../../Client";

export class ClientActivationDTO extends AbstractDTO {
    static ENABLED = "enabled"

    constructor(enabled: boolean) {
        super();

        this.enabled = enabled
    }

    enabled: boolean

    static fromClient = (client: Client): ClientActivationDTO => {
        return new ClientActivationDTO(client.enabled)
    };
}
import HiloReactComponent from "../../../tools/view/layout/HiloReactComponent";
import Client from "../../../models/Client";
import { FormListener } from "../../../tools/model/form/AbstractForm";
import FormSection from "../../../tools/view/form/FormSection";
import React from "react";
import UIToast from "../../../tools/view/toast/UIToast";
import ClientService from "../../../services/ClientService";
import ClientClientForm from "../../../models/form/client/ClientClientForm";
import { ClientClientDTO } from "../../../models/dto/client/ClientClientDTO";
import Spinner from "../../../tools/view/element/Spinner";
import UIError from "../../../tools/view/toast/UIError";

interface State {
    clientForm?: ClientClientForm;
    clients?: Array<Client>;
    loadingClients: boolean;
}

interface Props {
    client: Client;
    clientUpdated: () => void;
}

export default class AdminClientClientEditor
    extends HiloReactComponent<Props, State>
    implements FormListener<ClientClientForm, ClientClientDTO> {

    private readonly _clientService: ClientService;

    constructor(props: Props, state: State) {
        super(props, state);

        this._clientService = this._serviceRepository.resolve(ClientService.id) as ClientService;

        this.state = { loadingClients: false };
    }

    /**
     * componentDidMount
     */
    public componentDidMount(): void {
        super.componentDidMount();

        this._refreshForms();
    }

    /**
     * componentWillUnmount
     */
    public componentWillUnmount(): void {
        this.state.clientForm?.removeListener(this);

        super.componentWillUnmount();
    }

    /**
     * componentDidUpdate
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        super.componentDidUpdate?.(prevProps, prevState, snapshot);

        if (prevProps.client.id !== this.props.client.id) {
            this._refreshForms();
        }
    }

    /**
     * _initClientForm
     * @param client
     * @param clients
     * @private
     */
    private _initClientForm(client: Client, clients: Array<Client>): ClientClientForm {
        const clientForm = new ClientClientForm(client, clients);

        clientForm.addListener(this);

        return clientForm;
    }

    /**
     * _refreshForms
     * @private
     */
    private _refreshForms = async () => {
        const { client } = this.props;
        const { loadingClients, clients, clientForm } = this.state;

        if (loadingClients) return;
        if (!clients) return await this._loadClients();

        if (!clientForm) await this.setState({ clientForm: this._initClientForm(client, clients) });
    };

    private _loadClients = async () => {
        await this.setState({ loadingClients: true });

        try {
            const clients = (await this._clientService.findAll()).filter((client) => { return client.id !== this.props.client.id })
            await this.setState({ clients: clients, loadingClients: false });
            await this._refreshForms();
        } catch (e) {
            UIError.default.show(e.statusCode)
            await this.setState({ loadingClients: false });
        }
    };


    /**
     * onFormUpdate
     * @param form
     */
    public onFormUpdate = (form: ClientClientForm) => {
        this.setState({ clientForm: form })
    };

    /**
     * _submitForm
     * @param action
     * @param form
     * @private
     */
    private _submitForm = async (action: () => void, form: ClientClientForm) => {
        try {
            await action()
            form.statusCode = 200;
            UIToast.default.show(this.t.clientEdited);
            this.props.clientUpdated();
        } catch (e) {
            form.statusCode = e.status
            console.error(e);
        }
    }

    /**
     * _saveClient
     * @param dto
     * @private
     */
    private _saveClient = async (dto: FormData) => {
        const { clientForm } = this.state;

        if (!clientForm) return console.error("ClientForm undefined");

        await this._submitForm(() => this._clientService.updateClients(this.props.client, dto), clientForm)
    };


    render(): React.ReactNode {
        const { loadingClients, clientForm } = this.state;

        if (loadingClients || !clientForm) {
            return <Spinner large={true} />;
        }

        return <>
            <FormSection label={this.t.clients} form={clientForm} submit={this._saveClient} />
        </>
    }
}
import { AbstractDTO } from "../../../tools/model/dto/AbstractDTO";
import Client from "../../Client";

export class ClientClientDTO implements AbstractDTO {
    static CLIENT_IDS = "client_ids";

    constructor(client_ids: Array<string>) {
        this.client_ids = client_ids;
    }

    client_ids: Array<string>

    static fromClient = (client: Client): ClientClientDTO => {
        return new ClientClientDTO(client.clients);
    };
}
import Client from "../models/Client";
import QueryFilter from "../tools/model/pagination/Filters";
import ClientService, {PaginationFilters} from "../services/ClientService";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import {ClientAPIPagination} from "../pagination/ClientAPIPagination";

interface ClientPaginationFilters extends PaginationFilters {
    grantType?: string
}

export default class ClientDataProvider extends DataProvider<Client, ClientAPIPagination>{
    private readonly _clientService: ClientService;

    /**
     * constructor
     * @param clientService
     */
    public constructor(clientService: ClientService) {
        super(new PaginationController<Client, ClientAPIPagination>(new ClientAPIPagination()));

        this._clientService = clientService;
    }

    /**
     * load
     * @returns {Promise<Client[]>}
     */
    async load() : Promise<Client[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters: QueryFilter<ClientPaginationFilters> = new QueryFilter<ClientPaginationFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10
        });

        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`
        if (pagination.searchQuery) paginationFilters.fields.searchQuery = pagination.searchQuery;
        if (pagination.grantType) paginationFilters.fields.grantType = pagination.grantType;

        const pageResponse = await this._clientService.getPage<Client>(ClientService.LIST_ROUTE, paginationFilters);
        pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }
}
import {LabelAccessor} from "../../view/LabelAccessor";
import {Type} from "../../view/form/FormInput";

export abstract class Field<T> {
    name: string;
    title: LabelAccessor;
    errorLabel?: LabelAccessor;
    disabled: boolean;
    isValid: (value: T | Array<T>) => boolean;
    type: Type;
    required: boolean = true;
    value: T | Array<T> | undefined;
    defaultValue: T | Array<T> | undefined;
    abstract stringValue: () => string | undefined;

    constructor(
        name: string,
        title: LabelAccessor,
        isValid: (value: T | Array<T>) => boolean,
        type: Type,
        required: boolean,
        value: T | Array<T> | undefined,
        defaultValue: T | Array<T> | undefined,
        disabled: boolean = false,
        errorLabel?: LabelAccessor
    ) {
        this.name = name;
        this.title = title;
        this.errorLabel = errorLabel;
        this.isValid = isValid;
        this.required = required;
        this.defaultValue = defaultValue;
        this.value = value;
        this.type = type;
        this.disabled = disabled;
    }
}
import QueryFilter from "../tools/model/pagination/Filters";
import {PaginationFilters} from "../services/ClientService";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import HTTPService from "../tools/service/HTTPService";
import UserService from "../services/UserService";
import User from "../models/User";
import {UserAPIPagination} from "../pagination/UserAPIPagination";

interface UserPaginationFilters extends PaginationFilters {
    role?: string
}

export default class UserDataProvider extends DataProvider<User, UserAPIPagination> {
    private readonly _userService: UserService;
    private readonly _httpService: HTTPService;

    /**
     * constructor
     * @param userService
     * @param httpService
     */
    public constructor(userService: UserService, httpService: HTTPService) {
        super(new PaginationController<User, UserAPIPagination>(new UserAPIPagination()));

        this._httpService = httpService;
        this._userService = userService;
    }

    /**
     * load
     * @returns {Promise<User[]>}
     */
    public async load(): Promise<User[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters: QueryFilter<UserPaginationFilters> = new QueryFilter<UserPaginationFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10,
        });

        if (pagination.searchQuery) paginationFilters.fields.searchQuery = pagination.searchQuery;
        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`
        if (pagination.role) paginationFilters.fields.role = pagination.role;

        const pageResponse = await this._userService.getPage<User>(UserService.LIST_ROUTE, paginationFilters);
        pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }
}
import {Field} from "./Field";

export class FormException extends Error {
    readonly field: Field<any>;

    constructor(field: Field<any>) {
        super();

        this.field = field;
    }
}
import HiloReactComponent from "../../../tools/view/layout/HiloReactComponent";
import AdminUserEditIdentityForm from "../../../models/form/user/AdminUserEditIdentityForm";
import UserResetPasswordForm from "../../../models/form/user/UserResetPasswordForm";
import UserActivationForm from "../../../models/form/user/UserActivationForm";
import User from "../../../models/User";
import {AbstractForm, FormListener} from "../../../tools/model/form/AbstractForm";
import {UserEditIdentityDTO} from "../../../models/dto/user/UserEditIdentityDTO";
import {UserResetPasswordDTO} from "../../../models/dto/user/UserResetPasswordDTO";
import {UserActivationDTO} from "../../../models/dto/user/UserActivationDTO";
import FormSection from "../../../tools/view/form/FormSection";
import Colors from "../../../tools/model/enumerations/Colors";
import React from "react";
import UIToast from "../../../tools/view/toast/UIToast";
import UserService from "../../../services/UserService";

interface State {
    identityForm: AdminUserEditIdentityForm;
    passwordForm: UserResetPasswordForm;
    activationForm: UserActivationForm;
}

interface Props {
    user: User;
    userUpdated: () => void;
}

export default class AdminUserDetailsEditor
    extends HiloReactComponent<Props, State>
    implements FormListener<AdminUserEditIdentityForm | UserResetPasswordForm | UserActivationForm,
        UserEditIdentityDTO | UserResetPasswordDTO | UserActivationDTO> {

    private readonly _userService: UserService = this._serviceRepository.resolve(UserService.id) as UserService

    constructor(props: Props, state: State) {
        super(props, state);

        this.state = {
            activationForm: new UserActivationForm(props.user),
            identityForm: new AdminUserEditIdentityForm(props.user),
            passwordForm: new UserResetPasswordForm(props.user)
        }
    }

    /**
     * componentDidMount
     */
    public componentDidMount(): void {
        super.componentDidMount();

        this.state.activationForm.addListener(this);
        this.state.identityForm.addListener(this);
        this.state.passwordForm.addListener(this);
    }

    /**
     * componentWillUnmount
     */
    public componentWillUnmount(): void {
        this.state.activationForm.removeListener(this);
        this.state.identityForm.removeListener(this);
        this.state.passwordForm.removeListener(this);

        super.componentWillUnmount();
    }


    /**
     * componentDidUpdate
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        super.componentDidUpdate?.(prevProps, prevState, snapshot);

        if (prevProps.user.id !== this.props.user.id) {
            this._refreshForms();
        }
    }

    /**
     * _refreshForms
     * @private
     */
    private _refreshForms = () => {
        const {user} = this.props;

        this.state.activationForm.load(UserActivationDTO.fromUser(user))
        this.state.identityForm.load(UserEditIdentityDTO.fromUser(user))
        this.state.passwordForm.load(UserResetPasswordDTO.fromUser(user))
    };

    /**
     * onFormUpdate
     * @param form
     */
    public onFormUpdate = (form: AbstractForm<UserEditIdentityDTO | UserResetPasswordDTO | UserActivationDTO>) => {
        if (form instanceof UserResetPasswordForm) {
            this.setState({passwordForm: form});
        } else if (form instanceof UserActivationForm) {
            this.setState({activationForm: form})
        } else {
            this.setState({identityForm: form})
        }
    };

    /**
     * _submitForm
     * @param action
     * @param form
     * @private
     */
    private _submitForm = async (action: () => void, form: AbstractForm<any>) => {
        try {
            await action()
            form.statusCode = 200;
            UIToast.default.show(this.t.userEdited);
            this.props.userUpdated();
        } catch (e) {
            form.statusCode = e.status
            console.error(e);
        }
    }

    /**
     * _resetPassword
     * @param dto
     * @private
     */
    private _resetPassword = async (dto: FormData) => {
        await this._submitForm(() => this._userService.resetPassword(dto), this.state.passwordForm)
    };


    /**
     * _saveUser
     * @param dto
     * @private
     */
    private _saveUser = async (dto: FormData) => {
        await this._submitForm(() => this._userService.updateUserAsAdmin(this.props.user, dto), this.state.identityForm)
    };

    /**
     * _setUserActivation
     * @param dto
     * @returns {Promise<void>}
     * @private
     */
    private _setUserActivation = async (dto: FormData) => {
        await this._submitForm(() => this._userService.setActivation(this.props.user, dto), this.state.activationForm)
    }

    render(): React.ReactNode {
        const {passwordForm, identityForm, activationForm} = this.state;

        return <>
            <FormSection label={this.t.identity} form={identityForm} submit={this._saveUser}/>
            <FormSection label={this.t.userListColLastPwd} form={passwordForm} submit={this._resetPassword}
                         submitBtn={{color: Colors.red, labelAccessor: (t => t.userListResetPwdBtn)}}/>
            <FormSection label={this.t.accountStatus} form={activationForm} submit={this._setUserActivation}/>
        </>
    }
}
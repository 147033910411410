import HiloReactComponent from "../../../tools/view/layout/HiloReactComponent";
import User from "../../../models/User";
import {FormListener} from "../../../tools/model/form/AbstractForm";
import FormSection from "../../../tools/view/form/FormSection";
import React from "react";
import UIToast from "../../../tools/view/toast/UIToast";
import UserService from "../../../services/UserService";
import UserRoleForm from "../../../models/form/user/UserRoleForm";
import {UserRoleDTO} from "../../../models/dto/user/UserRoleDTO";

interface State {
    roleForm: UserRoleForm;
}

interface Props {
    user: User;
    userUpdated: () => void;
}

export default class AdminUserRoleEditor
    extends HiloReactComponent<Props, State>
    implements FormListener<UserRoleForm, UserRoleDTO> {

    private readonly _userService = this._serviceRepository.resolve(UserService.id) as UserService;

    constructor(props: Props, state: State) {
        super(props, state);

        this.state = {roleForm: new UserRoleForm(props.user)};
    }

    /**
     * componentDidMount
     */
    public componentDidMount(): void {
        super.componentDidMount();

        this.state.roleForm.addListener(this);
    }

    /**
     * componentWillUnmount
     */
    public componentWillUnmount(): void {
        this.state.roleForm?.removeListener(this);

        super.componentWillUnmount();
    }

    /**
     * componentDidUpdate
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        super.componentDidUpdate?.(prevProps, prevState, snapshot);

        if (prevProps.user.id !== this.props.user.id) {
            this.state.roleForm.load(UserRoleDTO.fromUser(this.props.user));
        }
    }

    /**
     * onFormUpdate
     * @param form
     */
    public onFormUpdate = (form: UserRoleForm) => {
        this.setState({roleForm: form})
    };

    /**
     * _submitForm
     * @param action
     * @param form
     * @private
     */
    private _submitForm = async (action: () => void, form: UserRoleForm) => {
        try {
            await action()
            form.statusCode = 200;
            UIToast.default.show(this.t.userEdited);
            this.props.userUpdated();
        } catch (e) {
            form.statusCode = e.status
            console.error(e);
        }
    }

    /**
     * _saveUser
     * @param dto
     * @private
     */
    private _saveUser = async (dto: FormData) => {
        const {roleForm} = this.state;

        if (!roleForm) return console.error("RoleForm undefined");

        await this._submitForm(() => this._userService.updateRoles(this.props.user, dto), roleForm)
    };


    render(): React.ReactNode {
        return <>
            <FormSection label={this.t.roles} form={this.state.roleForm}  submit={this._saveUser}/>
        </>
    }
}
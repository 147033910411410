import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";

export class UserEditPasswordDTO implements AbstractDTO {
    static OLD_PASSWORD = "oldPassword"
    static NEW_PASSWORD = "newPassword"

    constructor(oldPassword: string, newPassword: string) {
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
    }

    oldPassword: string
    newPassword: string
}
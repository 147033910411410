import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import User from "../../User";
import {Role} from "../../../tools/model/enumerations/Role";

export class UserRoleDTO implements AbstractDTO {
    static ROLES = "roles";

    constructor(roles: Array<Role>) {
        this.roles = roles;
    }

    roles: Array<Role>

    static fromUser = (user: User): UserRoleDTO => {
        return new UserRoleDTO(user.roles);
    };
}
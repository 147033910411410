export default abstract class Translation {
    format(value: string, ...params: string[]): string {
        params.forEach(param => value = value.replace("%%%", param));
        return value;
    }

    readonly languageKey: string = "";
    readonly languageFlag: string = "";
    readonly 401: string = "";
    readonly 404: string = "";
    readonly 409: string = "";
    readonly 422: string = "";
    readonly 500: string = "";
    readonly all: string = "";
    readonly admin: string = "";
    readonly loginFormMailPh: string = "";
    readonly loginFormPwdPh: string = "";
    readonly loginFormError: string = "";
    readonly vegetableTypeSelect: string = "";
    readonly nut: string = "";
    readonly flower: string = "";
    readonly cereal: string = "";
    readonly fruit: string = "";
    readonly navbarBtnEdit: string = "";
    readonly addBillModalBillNb: string = "";
    readonly addBillModalHelp: string = "";
    readonly selected: string = "";
    readonly see: string = "";
    readonly edit: string = "";
    readonly save: string = "";
    readonly cancel: string = "";
    readonly android: string = "";
    readonly iOS: string = "";
    readonly web: string = "";
    readonly information: string = "";
    readonly type: string = "";
    readonly bill: string = "";
    readonly bills: string = "";
    readonly manager: string = "";
    readonly clients: string = "";
    readonly usersAndAccess: string = "";
    readonly settings: string = "";
    readonly supplier: string = "";
    readonly suppliers: string = "";
    readonly group: string = "";
    readonly member: string = "";
    readonly number: string = "";
    readonly status: string = "";
    readonly delete: string = "";
    readonly action: string = "";
    readonly modelName: string = "";
    readonly fileName: string = "";
    readonly weight: string = "";
    readonly download: string = "";
    readonly creationDate: string = "";
    readonly headerLogout: string = "";
    readonly headerLogoutConfirm: string = "";
    readonly headerTitle: string = "";
    readonly headerNoData: string = "";
    readonly modalPositiveBtn: string = "";
    readonly modalNegativeBtn: string = "";
    readonly addUserModalDuplicate: string = "";
    readonly addUserModalMail: string = "";
    readonly addUserModalFirstName: string = "";
    readonly addUserModalLastName: string = "";
    readonly UserComponentResetPassword: string = "";
    readonly UserComponentResetPasswordConfirm: string = "";
    readonly UserComponentRemoveUser: string = "";
    readonly UserComponentRemoveUserConfirm: string = "";
    readonly UserComponentTitle: string = "";
    readonly UserComponentAddUserBtn: string = "";
    readonly groupComponentMemberListTab: string = "";
    readonly loading: string = "";
    readonly identifier: string = "";
    readonly clientListColID: string = "";
    readonly clientListColName: string = "";
    readonly clientListColEnabled: string = "";
    readonly clientListColScope: string = "";
    readonly clientListEmpty: string = "";
    readonly userListColID: string = "";
    readonly userListColRole: string = "";
    readonly userListColMultipleConnection: string = "";
    readonly userListColFirstName: string = "";
    readonly userListColLastName: string = "";
    readonly userListColLastPwd: string = "";
    readonly userListColPhone: string = "";
    readonly userListColAction: string = "";
    readonly userListEmpty: string = "";
    readonly userListResetPwdBtn: string = "";
    readonly userListRemoveUserFromGrpBtn: string = "";
    readonly userListClientsIds: string = "";
    readonly searchTitle: string = "";
    readonly searchByNamePH: string = "";
    readonly searchBtn: string = "";
    readonly searchFilterTitle: string = "";
    readonly searchClearBtn: string = "";
    readonly modelDescriptionCancelConfirm: string = "";
    readonly settingsPwdReset: string = "";
    readonly settingsResetPasswordConfirm: string = "";
    readonly alertOk: string = "";
    readonly alertCancel: string = "";
    readonly switchLanguage: string = "";
    readonly formInvalidField: string = "";
    readonly formPasswordInvalid: string = "";
    readonly userAdded: string = "";
    readonly userEdited: string = "";
    readonly clientAdded: string = "";
    readonly clientEdited: string = "";
    readonly password: string = "";
    readonly client_credentials: string = "";
    readonly refresh_token: string = "";
    readonly grantTypes: string = "";
    readonly openRegistration: string = "";
    readonly openRegistrationShort: string = "";
    readonly redirectUri: string = "";
    readonly details: string = "";
    readonly list: string = "";
    readonly allClients: string = "";
    readonly allUsers: string = "";
    readonly expired: string = "";
    readonly valid: string = "";
    readonly token: string = "";
    readonly tokenListEmpty: string = "";
    readonly tokenListAccessToken: string = "";
    readonly tokenListRefreshToken: string = "";
    readonly tokenListCreatedAt: string = "";
    readonly tokenListExpiresIn: string = "";
    readonly pageTotal: string = "";
    readonly identity: string = "";
    readonly oldPassword: string = "";
    readonly newPassword: string = "";
    readonly saved: string = "";
    readonly revoke: string = "";
    readonly revoked: string = "";
    readonly accessTokenCopied: string = "";
    readonly refreshTokenCopied: string = "";
    readonly accountStatus: string = "";
    readonly roles: string = "";
    readonly ADMIN: string = "";
    readonly MANAGER: string = "";
    readonly STANDARD: string = "";
    readonly newUser: string = "";
    readonly newClient: string = "";
    readonly userMenu: string = "";
    readonly pages: string = "";
    readonly devices: string = "";
    readonly device: string = "";
    readonly uuid: string = "";
    readonly clientId: string = "";
    readonly clientSecret: string = "";
    readonly deviceListEmpty: string = "";
    readonly copied: string = "";
    readonly enabled: string = "";
    readonly activation: string = "";
    readonly newDevice: string = "";
    readonly deviceCreated: string = "";
    readonly deviceRemoveConfirmation: string = "";
    readonly formPhoneInvalid: string = "";
    readonly name: string = "";
    readonly deviceName: string = "";
    //PH
}
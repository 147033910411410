import HiloReactComponent from "../layout/HiloReactComponent";
import {LabelAccessor} from "../LabelAccessor";
import React from "react";

interface Props {
    content?: LabelAccessor
}

export default class Error extends HiloReactComponent<Props, {}> {
    public render(): React.ReactNode {
        const {content} = this.props;

        if (!content) {
            return <></>
        }

        return <p className={"toast error"}>
            <img src={"/img/ko_logo.png"} alt={"ko"}/>
            {content(this.t)}
        </p>
    }
}
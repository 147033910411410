import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import User from "../../User";

export class UserResetPasswordDTO implements AbstractDTO {
    static EMAIL = "email";

    constructor(email: string) {
        this.email = email;
    }

    email: string

    static fromUser = (user: User): UserResetPasswordDTO => {
        return new UserResetPasswordDTO(user.email);
    }
}
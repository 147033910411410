import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Device from "../../Device";

export class DeviceDTO extends AbstractDTO {
    static UUID = "uuid"
    static NAME = "name";

    constructor(uuid: string, name?: string) {
        super();

        this.uuid = uuid;
        this.name = name;
    }

    uuid: string
    name?: string

    static fromDevice = (device: Device): DeviceDTO => {
        return new DeviceDTO(
            device.uuid,
            device.name
        )
    };
}
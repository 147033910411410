import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import User from "../../User";

export class UserClientDTO implements AbstractDTO {
    static CLIENT_IDS = "client_ids";

    constructor(client_ids: Array<string>) {
        this.client_ids = client_ids;
    }

    client_ids: Array<string>

    static fromUser = (user: User): UserClientDTO => {
        return new UserClientDTO(user.clients);
    };
}